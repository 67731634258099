import React from 'react'


import Header from '../layout/Header/header'
import Sidebar from '../layout/Sidebar/sidebar'
import Footer from '../layout/Footer/footer'

const MainTemplate = props => (
    <div className="MainTemplate">
        <Header />
        <Sidebar />
        <div className="content-wrapper px-4 py-2">
            <main>
                {
                    props.children
                }
            </main>
        </div>
        <Footer />
    </div>
)

export default MainTemplate