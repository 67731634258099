import React from 'react'

import Container from '../common/Container/Container'
import SectionHeader from '../common/Section/SectionHeader'
import Row from '../common/Row/Row'
import Col from '../common/Col/Col'
import SmallBox from '../common/SmallBox/SmallBox'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getProducts } from '../products/productActions'
import { useEffect } from 'react'
import SectionTitle from '../common/Section/SectionTitle'

const Home = props => {
    const {
        products: { data:productsData },
        getProducts
    } = props

    useEffect(() => {
        getProducts()
    }, [getProducts])

    return (
        <div className="Home">
            <SectionHeader>
                <Container fluid>
                    <Row>
                        <Col size={ [ 'col-sm-6' ]  }>
                            <SectionTitle>
                                Dashboard
                            </SectionTitle>
                        </Col>
                    </Row>
                </Container>
            </SectionHeader>
            <Container fluid>
                <Row>
                    <Col size={ [ 'col-lg-3' ] }>
                        <SmallBox
                            variant="info"
                            value={productsData.length}
                            title="Produtos"
                            icon="fas fa-box" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getProducts
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Home)