import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '../common/Button/Button'
import Input from '../common/Input/Input'

import * as bootstrap from 'bootstrap'

import { reduxForm, Field } from 'redux-form'
import { required, isURL } from '../forms/validators'

import { connect } from 'react-redux'

let ProductForm = props => {
    const {
        handleSubmit,
        show,
        onCancel,
        onClose,
    } = props

    const ref = useRef(null)

    useEffect(() => {
        if (ref && ref.current) {
            const modal = bootstrap.Modal.getOrCreateInstance(ref.current)

            if (show)
                modal.show()
            else
                modal.hide()
        }
    }, [ show, ref ])

    return (
        <div
            ref={ref}
            className="modal fade"
            tabIndex="-1"
            aria-labelledby="ProductForm"
            aria-hidden={ `${show ? 'true' : 'false'}` }>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="ProductForm">Produto</h5>
                            <Button
                                type="button"
                                className="btn-close"
                                onClick={ onClose }
                                aria-label="Close">
                                <i className="fas fa-xmark"></i>
                            </Button>
                        </div>
                        <div className="modal-body">
                                <Field
                                    name="_id"
                                    type="hidden"
                                    component={Input} />

                                <Field
                                    name="description"
                                    placeholder="Descreva o produto"
                                    label="Nome"
                                    validate={required}
                                    component={Input} />

                                <Field
                                    name="source_url"
                                    label="Link do fornecedor"
                                    placeholder="Shopee, AliExpress..."
                                    validate={isURL}
                                    component={Input} />

                                <Field
                                    name="variant_number"
                                    label="Variante"
                                    placeholder="Ex.: 1"
                                    type="number"
                                    min="1"
                                    component={Input} />
                                

                                <Field
                                    name="wix_url"
                                    label="Link na Wix"
                                    placeholder="Cart in Hand"
                                    validate={isURL}
                                    component={Input} />
                        </div>
                        <div className="modal-footer">
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onCancel}>
                                Cancelar
                            </Button>
                            <Button
                                type="submit"
                                variant="primary">Salvar</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

ProductForm.propTypes = {
    data: PropTypes.any,
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
}

ProductForm = reduxForm({
    form: 'product',
    enableReinitialize: true
})(ProductForm)


const mapStateToProps = state => ({
    initialValues: state.products.currentProduct
})

export default connect(mapStateToProps)(ProductForm)