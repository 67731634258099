import React from 'react'

import logo from 'admin-lte/dist/img/AdminLTELogo.png'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const Sidebar = props => {
    const { auth: { user } } = props

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="index3.html" className="brand-link">
                <img src={logo} alt="AdminLTE Logo" className="brand-image img-circle elevation-3"
                    style={{ opacity: .8 }} />
                <span className="brand-text font-weight-light">AdminLTE 3</span>
            </a>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="http://lorempixel.com.br/400/400" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">{ user.name }</a>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
                        <li className="nav-item">
                            <a href="#" className="nav-link active">
                                <i className="nav-icon fas fa-chart-simple"></i>
                                <p>
                                    Dashboard
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-folder-plus"></i>
                                <p>
                                    Cadastros
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/produtos">
                                        <i className="fas fa-box nav-icon"></i>
                                        <p>Produtos</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/usuarios">
                                        <i className="fas fa-user nav-icon"></i>
                                        <p>Usuários</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-cog"></i>
                                <p>
                                    Configurações
                                </p>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Sidebar)