import axios from 'axios'

const BASER_URL = process.env.REACT_APP_ENDPOINT_BASEURL
const apiClient = axios.create({
    baseURL: BASER_URL
})

apiClient.interceptors.request.use(config => {
    const user = JSON.parse(localStorage.getItem('cartinhand_user'))

    if (user) {
        const token = user.token
        config.headers['authorization'] = token
    }

    return config
})

export {
    BASER_URL,
    apiClient
}