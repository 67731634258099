import React from 'react'

const Footer = props => (
    <footer className="main-footer">
        <div className="float-right d-none d-sm-inline">
            v0.1
        </div>
        <strong>Copyright © 2022 <a href="https://www.cartinhand.com.br">Cart in Hand</a>.</strong> Todos os direitos reservados.
    </footer>
)

export default Footer