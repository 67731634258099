import axios from "axios"
import { toast } from "react-toastify"
import { ACTION_TYPE_PLACES_FETCHED, ACTION_TYPE_PLACES_FETCHING } from "./placesActionTypes"

const VIA_CEP_BASE_URL = 'https://viacep.com.br/ws' 

export function getPlace(cepNumber) {
    if (!cepNumber)
        return

    return dispatch => {
        const url = `${VIA_CEP_BASE_URL}/${cepNumber}/json`

        dispatch(fetching(true))

        axios.get(url)
            .then(resp => dispatch({ type: ACTION_TYPE_PLACES_FETCHED, payload: resp.data }))
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    toast.error('CEP inválido')
                } else {
                    toast.error('Falha ao consultar CEP')
                }
            }).finally(() => dispatch(fetching(false)))
    }
}

export function fetching(value) {
    return { type: ACTION_TYPE_PLACES_FETCHING, payload: value }
}