import React from 'react'

import { Routes, Route } from 'react-router-dom'

import WithAuth from '../auth/withAuth'

// Páginas

import Home from '../home/home'
import Logout from '../auth/logout'
import Products from '../products/products'

const Router = props => (
    <Routes>
        <Route path="/" exact element={<WithAuth><Home /></WithAuth>} />
        <Route path="produtos" element={<WithAuth><Products /></WithAuth>} />
        <Route path="logout" element={<Logout />} />
    </Routes>
)

export default Router