import { combineReducers } from "redux"
import { authReducer } from "../auth/authReducer"
import { productReducer } from "../products/productReducer"
import { reducer as formReducer } from 'redux-form'
import { placesReducer } from "../places/placesReducer"

const rootReducer = combineReducers({
    auth: authReducer,
    products: productReducer,
    places: placesReducer,
    form: formReducer
})

export default rootReducer