import React from 'react'
import PropTypes from 'prop-types'

const DataTable = props => {
    const {
        headers = [],
        data    = [],
        renderHeader,
        renderItem
    } = props

    return (
        <table className="table table-bordered table-hover dataTable dtr-inline">
            <thead>
                <tr>
                    { headers.map(renderHeader) }
                </tr>
            </thead>
            <tbody>
                { data.map(renderItem) }
            </tbody>
        </table>
    )
}

DataTable.propTypes = {
    data: PropTypes.array,
    headers: PropTypes.array,
    renderHeader: PropTypes.func,
    renderItem: PropTypes.func
}

export default DataTable