export function required(value) {
    return value ? undefined : 'Campo obrigatório'
}

export function isURL(value) {
    const urlRegEx = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

    return urlRegEx.test(value) ? undefined : 'URL inválida'
}

export function isCEP(value) {
    const onlyNums = value.replace(/\D/g, '')

    return onlyNums.length === 8 ? undefined : 'CEP inválido'
}