import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

const Col = props => {
    const { className, size } = props

    const classes = useMemo(() => {
        let c = `col ${className || ''} `

        if (size) {
            let sizes = size.join(' ')
            c += sizes
        }

        return c
    }, [ className, size ])

    return (
        <div className={classes}>
            { props.children }
        </div>
    )   
}

Col.propTypes = {
    size: PropTypes.arrayOf(PropTypes.string)
}

export default Col