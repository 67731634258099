import { apiClient } from "../config/api"
import {
    ACTION_TYPE_PRODUCTS_FETCHED,
    ACTION_TYPE_PRODUCTS_FETCHING,
    ACTION_TYPE_PRODUCT_CREATED,
    ACTION_TYPE_PRODUCT_DELETED,
    ACTION_TYPE_PRODUCT_SET_CURRENT,
    ACTION_TYPE_PRODUCT_UPDATED,
} from "./productActionTypes"
import { toast } from "react-toastify"

import {
    PRODUCT_STATUS_CHECKING,
    PRODUCT_STATUS_ERROR,
    PRODUCT_STATUS_PENDING
} from './productsStatus'

export function getProducts() {
    return dispatch => {
        fetching(true)
        
        apiClient.get('/api/products')
            .then(resp => {
                const data = resp.data.map(p => ({ ...p, status: PRODUCT_STATUS_PENDING }))
                dispatch({ type: ACTION_TYPE_PRODUCTS_FETCHED, payload: data })
            })
            .catch((err) => {
                toast.error('Falha ao consultar produtos')
            })
            .finally(() => fetching(false))
    }
}

export function saveProduct(product) {
    return dispatch => {
        if (product._id) {
            // Atualiza

            apiClient.put(`/api/products/${product._id}`, product)
                .then(resp => {
                    dispatch({ type: ACTION_TYPE_PRODUCT_UPDATED, payload: resp.data })
                    toast.success('Produto atualizado')
                })
                .catch(err => {
                    if (err.response.data && err.response.data.errors) {
                        toast.error(err.response.data.errors[0])
                    } else {
                        toast.error('Falha ao atualizar produto')
                    }
                })
        } else {
            // Cria

            apiClient.post('/api/products/', product)
                .then(resp => {
                    dispatch({
                        type: ACTION_TYPE_PRODUCT_CREATED,
                        payload: { ...resp.data, status: PRODUCT_STATUS_PENDING }
                    })
                    toast.success('Produto criado')
                })
                .catch(err => {
                    if (err.response.data && err.response.data.errors) {
                        toast.error(err.response.data.errors[0])
                    } else {
                        toast.error('Falha ao criar produto')
                    }
                })
        }
    }
}

export function deleteProduct(product) {
    return dispatch => {
        apiClient.delete(`/api/products/${product.id}`)
            .then(resp => {
                toast.success('Produto excluído')
                dispatch({ type: ACTION_TYPE_PRODUCT_DELETED, payload: resp.data })
            })
            .catch(err => {
                toast.error('Falha ao excluir produto')
            })
    }
}

// Realizar checagem de três em três produtos

function doRequestsByParts(products, count = 3, beforeStart, afterDone, onError) {
    if (products.length === 0)
        return

    const parts    = products.splice(0, count)

    if (beforeStart)
        beforeStart(parts)

    const requests = Promise.allSettled(parts.map(p => 
        new Promise((resolve, reject) => {
            apiClient.get('/api/status/products', {
                params: {
                    url: p.source_url,
                    variant_number: p.variant_number
                }
            }).then(resp => {
                const data          = resp.data
                const statusRequest = apiClient.get('/api/status/productStatus', {
                    params: {
                        url: p.wix_url,
                        source_value: data.value,
                        source_shipping_fee: data.shipping_fee
                    }
                })

                statusRequest.then(res => {
                    resolve({ ...p, ...data, ...res.data })
                }).catch(err => reject(err))
            }).catch(err => reject(err))
        })
    ))

    requests.then(resp => {
        afterDone(resp, parts)
        doRequestsByParts(products, count, beforeStart, afterDone, onError)
    }).catch(err => {
        if (onError)
            onError(err, parts)
        
        doRequestsByParts(products, count, beforeStart, afterDone, onError)
    })
}

export function checkStatus(products) {
    return dispatch => {
        doRequestsByParts(
            products,
            3,
            (parts) => {
                parts.map(p => {
                    dispatch(setStatus(p, PRODUCT_STATUS_CHECKING))
                })
            },
            (results, data) => {
                results.map((result, i) => {
                    if (result.status === 'fulfilled') {
                        const p = result.value
                        dispatch(checkProduct(p, true))
                    } else {
                        const p = data[i]
                        dispatch(setStatus(p, PRODUCT_STATUS_ERROR))
                    }
                })
            },
            (err, parts) => {
                parts.map(p => {
                    dispatch(setStatus(p, PRODUCT_STATUS_ERROR))
                })
            }
        )
    }
}

export function checkProduct(product, value) {
    return { type: ACTION_TYPE_PRODUCT_UPDATED, payload: { ...product, checked: value } }
}

export function setCurrentProduct(product) {
    return { type: ACTION_TYPE_PRODUCT_SET_CURRENT, payload: product }
}

export function setStatus(product, status) {
    return { type: ACTION_TYPE_PRODUCT_UPDATED, payload: { ...product, status } }
}

export function fetching(val) {
    return { type: ACTION_TYPE_PRODUCTS_FETCHING, payload: val }
}