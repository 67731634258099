import React from 'react'
import Dialog from '../common/Dialog/Dialog'

const DeleteProductDialog = props => (
    <Dialog
        { ...props }
        title="Deletar produto"
        text="Deseja deletar o produto?" />
)

export default DeleteProductDialog