import React from 'react'

import PropTypes from 'prop-types'

const Container = props => {
    const { fluid, children } = props

    return (
        <div className={`container${fluid ? '-fluid' : ''}`}>
            { children }
        </div>
    )
}

Container.propTypes = {
    fluid: PropTypes.bool
}

export default Container