import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

const Button = props =>  {
    const { className, variant } = props

    const classes = useMemo(() => {
        return `${className} btn btn-${variant || 'default'}`
    }, [className, variant])

    return (
        <button {...props} className={ classes }>
            { props.children }
        </button>
    )
}

Button.propTypes = {
    variant: PropTypes.string
}

export default Button