import './App.css'

import MainTemplate from './template/main'
import Routes from './config/routes'

import { connect } from 'react-redux'
import Auth from './auth/auth'

const App = props => {
  const {
    auth: { user }
  } = props

  return (
      <div className="App">
         {
          user
            ? (
              <MainTemplate>
                <Routes />
              </MainTemplate>
            ) : <Auth />
         }
      </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(App)
