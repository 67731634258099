import React from 'react'
import PropTypes from 'prop-types'

const Input = props => {
    const {
        input,
        label,
        id,
        placeholder,
        meta: { touched, error }
    } = props

    return (
        <div className="form-group">
            { label && <label htmlFor={id} className="form-label">{label}</label> }
            
            <input {...props} { ...input } className={`form-control ${touched && error ? 'is-invalid' : ''}`} id={id} placeholder={placeholder} />

            { touched && (error && <div className="invalid-feedback">{ error }</div>) }
        </div>
    )
}

Input.propTypes = {
    label: PropTypes.string
}

export default Input