import React, { useCallback } from 'react'
import LoginForm from './loginForm'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { login } from './authActions'

const Auth = props => {
    const {
        login
    } = props

    const handleSubmit = useCallback(({ email, password }) => {
        login(email, password)
    }, [login])

    return (
        <div className="Auth">
            <div className="login-page">
                <LoginForm onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    login
}, dispatch)

export default connect(null, mapDispatchToProps)(Auth)