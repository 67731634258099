import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button'

const RowActions = props => {
    const { onDelete, onEdit, onCheckStatus } = props

    return (
        <div className="RowActions d-flex justify-content-center">
            <Button
                className="mr-2"
                variant="info"
                onClick={onEdit}>
                <i className="fas fa-pencil"></i>
            </Button>
            <Button
                className="mr-2"
                variant="danger"
                onClick={onDelete}>
                <i className="fas fa-trash-can"></i>
            </Button>
            <Button variant="dark" onClick={onCheckStatus}>
                <i className="fas fa-magnifying-glass-dollar"></i>
            </Button>
        </div>
    )
}

RowActions.propTypes = {
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onCheckStatus: PropTypes.func
}

export default RowActions