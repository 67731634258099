import React from 'react'
import { Field, reduxForm } from 'redux-form'

import EmailField from '../common/EmailField/EmailField'
import PasswordField from '../common/PasswordField/PasswordField'


let LoginForm = props => {
    const { handleSubmit } = props

    return (
        <div className="login-box">
            <div className="login-logo">
                <a href="#"><b>Admin</b>LTE</a>
            </div>

            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Entre para continuar</p>
                    <form onSubmit={handleSubmit}>
                        <Field
                            className="mb-3"
                            placeholder="E-mail"
                            name="email"
                            component={EmailField} />
                        
                        <Field
                            className="mb-3"
                            placeholder="Senha"
                            name="password"
                            component={PasswordField} />

                        <div className="row">
                            <div className="col-8">
                                <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Continuar conectado
                                        </label>
                                </div>
                            </div>

                            <div className="col-4">
                                <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                            </div>

                        </div>
                    </form>

                    <p className="mb-1">
                        <a href="forgot-password.html">Esqueci a senha</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

LoginForm = reduxForm({
    form: 'login'
})(LoginForm)

export default LoginForm