import React, {
    useCallback,
    useState,
    useMemo,
    useEffect
} from 'react'

import SectionHeader from '../common/Section/SectionHeader'
import Card from '../common/Card/Card'
import CardBody from '../common/Card/CardBody'
import DataTable from '../common/DataTable/DataTable'
import CardHeader from '../common/Card/CardHeader'
import Row from '../common/Row/Row'
import Col from '../common/Col/Col'
import Container from '../common/Container/Container'
import Button from '../common/Button/Button'
import Progress from '../common/Progress/Progress'
import ProductForm from './productForm'
import Spinner from '../common/Spinner/Spinner'
import PlaceForm from '../places/placeForm'

import TableRow from '../common/DataTable/TableRow'
import Badge from '../common/Badge/Badge'
import RowActions from '../common/DataTable/RowActions'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    getProducts,
    saveProduct,
    checkStatus,
    deleteProduct,
    setCurrentProduct
} from './productActions'

import { formatMoney } from '../currency/utils'
import {
    PRODUCT_STATUS_CHECKING,
    PRODUCT_STATUS_ERROR,
    PRODUCT_STATUS_FLOOR,
    PRODUCT_STATUS_PENDING,
    PRODUCT_STATUS_ROOF
} from './productsStatus'
import SectionTitle from '../common/Section/SectionTitle'
import { reset } from 'redux-form'
import DeleteProductDialog from './deleteProductDialog'

const Products = props => {
    const {
        products: { data, currentProduct },
        getProducts,
        saveProduct,
        deleteProduct,
        setCurrentProduct,
        checkStatus,
        reset
    } = props
    const [ showForm, setShowForm ]                 = useState(false)
    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const checkingProgress          = useMemo(() => {
        const checkedProducts = data.filter(p => p.checked)

        return (checkedProducts.length / data.length) * 100
    }, [ data ])

    const [ headers ] = useState([
        "Descrição",
        "Link de origem",
        "Link da Wix",
        "Valor origem R$",
        "Frete origem R$",
        "Valor Wix R$",
        "Valor atual R$",
        "Status",
        "Ações"
    ])

    useEffect(() => {
        getProducts() 
    }, [ getProducts ])

    const closeForm  = useCallback(() => {
        setCurrentProduct(null)
        setShowForm(false)
        reset('product')
    }, [ setShowForm, setCurrentProduct ])

    const handleSubmit = useCallback(data => {
        saveProduct(data)
        closeForm()
    }, [ saveProduct, closeForm ])

    const editProduct   = useCallback(p => {
        setCurrentProduct(p)
        setShowForm(true)
    }, [ setCurrentProduct, setShowForm ])

    const deleteCurrentProduct = useCallback(() => {
        deleteProduct(currentProduct)
    }, [ currentProduct, deleteProduct ])

    const closeDeleteDialog = useCallback(() => {
        setShowDeleteDialog(false)
        setCurrentProduct(null)
    }, [setCurrentProduct, setShowDeleteDialog])

    const renderHeader = useCallback((header,i) => (
        <th key={ `h${i}` }>
            { header }
        </th>
    ), [])

    const startChecking = useCallback(() => {
        checkStatus([ ...data ])
    }, [ checkStatus, data ])

    const checkProduct = useCallback(product => {
        checkStatus([ product ])
    }, [])

    const renderItem = useCallback((item, i) => {
        let statusClass = null

        switch (item.status) {
            case PRODUCT_STATUS_FLOOR:
                statusClass = 'dark'
                break
            case PRODUCT_STATUS_ERROR:
                statusClass = 'danger'
                break
            case PRODUCT_STATUS_ROOF:
                statusClass = 'purple'
                break
            case PRODUCT_STATUS_PENDING:
                statusClass = 'warning'
                break
            case PRODUCT_STATUS_CHECKING:
                statusClass = 'info'
                break
            default:
                statusClass = 'success'
        }

        return (
            <TableRow key={item._id} odd={ i % 2 !== 0 }>
                <td>{ item.description }</td>
                <td><a href={ item.source_url } className="primary-link" target="_blank" rel="noreferrer">{ item.description }</a></td>
                <td><a href={ item.wix_url } className="primary-link" target="_blank" rel="noreferrer">{ item.description }</a></td>
                <td className="text-center">{
                    item.status === PRODUCT_STATUS_CHECKING
                        ? <Spinner />
                        : item.value ? formatMoney(item.value) : '-'
                }</td>
                <td className="text-center">{
                    item.status === PRODUCT_STATUS_CHECKING
                        ? <Spinner />
                        : item.shipping_fee ? formatMoney(item.shipping_fee) : '-'
                }</td>
                <td className="text-center">
                    {
                        item.status === PRODUCT_STATUS_CHECKING
                            ? <Spinner />
                            : item.previous_value ? formatMoney(item.previous_value) : '-'
                    }
                </td>
                <td className="text-center">{
                    item.status === PRODUCT_STATUS_CHECKING
                        ? <Spinner />
                        : item.current_value ? formatMoney(Math.round(item.current_value)) : '-'
                }</td>
                <td><Badge variant={statusClass}>{ item.status ? item.status : PRODUCT_STATUS_PENDING }</Badge></td>
                <td>
                    <RowActions
                        onEdit={() => editProduct(item)}
                        onDelete={ () => {
                            setShowDeleteDialog(true)
                            setCurrentProduct(item)
                        } }
                        onCheckStatus={ () => checkProduct(item) } />
                </td>
            </TableRow>
        )
    }, [])

    return (
        <div className="Products">
            <SectionHeader>
                <Container fluid>
                    <Row>
                        <Col size={ [ 'col-sm-6' ]  }>
                            <SectionTitle>
                                Produtos <small>{ data.length }</small>
                            </SectionTitle>
                        </Col>
                    </Row>
                </Container>
            </SectionHeader>
            <Card>
                <CardHeader>
                    <Row>
                        <Col size={ [ 'col-sm-6' ] }>
                            <Row>
                                <Col size={[ 'col-sm-8' ]}>
                                    <PlaceForm />
                                </Col>
                                <Col size={[ 'col-sm-4' ]}>
                                    <Button
                                        variant="outline-secondary"
                                        type="button"
                                        id="button-addon2"
                                        onClick={ startChecking }>
                                        Iniciar verificação
                                    </Button>
                                    <Progress value={checkingProgress} />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center" size={ [ 'col-sm-6' ] }>
                            <Button className="mr-2" variant="success" onClick={() => setShowForm(true)}>
                                <i className="fas fa-plus mr-2 d-inline"></i>
                                Novo produto
                            </Button>
                            <Button variant="danger">
                                <i className="fas fa-trash-can mr-2"></i>
                                Excluir
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <DataTable
                        headers={headers}
                        renderHeader={renderHeader}
                        renderItem={renderItem}
                        data={data} />
                </CardBody>
            </Card>

            <ProductForm
                show={showForm}
                onSubmit={handleSubmit}
                onCancel={ closeForm }
                onClose={ closeForm } />

            <DeleteProductDialog
                show={showDeleteDialog}
                onPositive={deleteCurrentProduct}
                onNegative={closeDeleteDialog}/>
        </div>
    )
}

const mapStateToProps    = state => ({
    products: state.products
})
const mapDispatchToProps = dispatch => bindActionCreators({
    getProducts,
    saveProduct,
    checkStatus,
    deleteProduct,
    setCurrentProduct,
    reset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Products)