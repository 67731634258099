import React from 'react'
import { Link } from 'react-router-dom'

const Header = props => (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
            </li>
        </ul>

        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <Link
                    className="nav-link"
                    to="/logout" role="button">
                    <i className="fas fa-power-off"></i>
                </Link>
            </li>
        </ul>
    </nav>
)

export default Header