import React from 'react'
import PropTypes from 'prop-types'

const Progress = ({ value, min, max }) => (
    <div className="progress">
        <div
            style={{
                width: `${value}%`
            }}
            className="progress-bar"
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin={min}
            aria-valuemax={max}>
            { value }%
        </div>
    </div>
)

Progress.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number
}

Progress.defaultProps = {
    min: 0,
    max: 100,
    value: 0
}

export default Progress