import { useEffect } from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { logout } from "./authActions"

import { useNavigate } from 'react-router-dom'

const Logout = props => {
    const { logout } = props
    const navigate   = useNavigate()

    useEffect(() => {
        logout()
        navigate('/')
    }, [logout, navigate])

    return null
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout
}, dispatch)

export default connect(null, mapDispatchToProps)(Logout)