import { useEffect } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validateToken } from './authActions'
import { apiClient } from '../config/api'

const WithAuth = props => {
    const { auth: { user, valid }, validateToken } = props

    useEffect(() => {
        validateToken(user.token)
    }, [user, validateToken])

    if (user && valid) {
        return props.children
    } else {
        return null
    }
}

const mapStateToProps    = state => ({
    auth: state.auth
})

const mapDispatchToProps = dispatch => bindActionCreators({
    validateToken
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth)