import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getPlace } from './placesActions'

import { reduxForm, Field } from 'redux-form'

import Card from '../common/Card/Card'
import CardBody from '../common/Card/CardBody'
import { isCEP, required } from '../forms/validators'
import { normalizeCep } from '../forms/normalizers'
import { parseCep } from '../forms/parsers'
import { useEffect } from 'react'

let PlaceForm = props => {
    const {
        handleSubmit,
        places: { data, loading },
        getPlace,
        onLoad
    } = props

    useEffect(() => {
        if (onLoad && data) {
            onLoad(data)
        }
    }, [ data ])

    const onSubmit = useCallback(({ cep }) => {
        getPlace(parseCep(cep))
    }, [])

    const renderField = useCallback((props) => {
        const {
            input,
            id,
            placeholder,
            meta: { touched, error }
        } = props

        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <input
                        id={id}
                        placeholder={placeholder}
                        { ...input }
                        className={`form-control ${touched && error ? 'is-invalid' : ''}`}
                        aria-describedby="button-addon2" />
                    <button className="btn btn-primary" type="submit" id="button-addon2">
                        {
                            loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                        {
                            loading ? 'Buscando...' : 'Buscar'
                        }
                    </button>
                    { touched && (error && <div className="invalid-feedback">{ error }</div>) }
                </div>
                { data &&
                    (
                        <div><i className="fas fa-map-marker text-primary d-inline-block mr-2"></i>{ data.localidade }, { data.uf }</div>) }
            </div>
        )
    }, [loading])

    return (
        <Card>
            <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Field
                        type="text"
                        placeholder="CEP"
                        name="cep"
                        component={renderField}
                        validate={[ required, isCEP ]}
                        normalize={ normalizeCep } />
                </form>
            </CardBody>
        </Card>
    )
}

PlaceForm.propTypes = {
    onLoad: PropTypes.func
}

PlaceForm = reduxForm({
    form: 'place',
    initialValues: {
        cep: '46880000'
    }
})(PlaceForm)

const mapStateToProps = state => ({
    places: state.places
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getPlace
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PlaceForm)