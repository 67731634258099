import React from 'react'
import PropTypes from 'prop-types'

const TableRow = props => {
    const { className, odd } = props

    return (
        <tr className={`${className} ${ odd ? 'odd' : 'even' }`}>
            { props.children }
        </tr>
    )
}

TableRow.propTypes = {
    odd: PropTypes.bool
}

export default TableRow