import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const Badge = props => {
    const { className, variant } = props

    const classes = useMemo(() => {
        let c = `${className || ''} badge bg-${variant}`

        return c
    }, [className, variant])

    return (
        <span className={classes}>{ props.children }</span>
    )
}

Badge.propTypes = {
    variant: PropTypes.string,
}

export default Badge