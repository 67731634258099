import {
    ACTION_TYPE_PRODUCTS_FETCHED,
    ACTION_TYPE_PRODUCTS_FETCHING,
    ACTION_TYPE_PRODUCT_CREATED,
    ACTION_TYPE_PRODUCT_SET_CURRENT,
    ACTION_TYPE_PRODUCT_UPDATED,
} from "./productActionTypes";

const initialState = {
    data: [],
    loading: false,
    currentProduct: null
}

export function productReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE_PRODUCTS_FETCHED:
            return { ...state, data: action.payload }
        case ACTION_TYPE_PRODUCTS_FETCHING:
            return { ...state, loading: action.payload }
        case ACTION_TYPE_PRODUCT_CREATED: {
            let products = [ ...state.data ]
            products.push(action.payload)

            return { ...state, data: products }
        }
        case ACTION_TYPE_PRODUCT_UPDATED: {
            let products   = [ ...state.data ]
            const product  = action.payload
            const { _id }  = product

            let indexOf = products.findIndex(p => {
                return p._id === _id
            })

            if (indexOf !== -1) {
                products[indexOf] = { ...products[indexOf], ...product }

                return { ...state, data: products }
            }

            return state
        }
        case ACTION_TYPE_PRODUCT_SET_CURRENT:
            return { ...state, currentProduct: action.payload }
        default:
            return state
    }
}