import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import * as bootstrap from 'bootstrap'

const Dialog = props => {
    const {
        show,
        title,
        text,
        onPositive,
        onNegative,
        onClose
    } = props

    const ref = useRef(null)

    useEffect(() => {
        if (ref && ref.current) {
            const modal = bootstrap.Modal.getOrCreateInstance(ref.current)
            
            if (show)
                modal.show()
            else
                modal.hide()
        }
    }, [ref, show])

    return (
        <div
            ref={ref}
            className="modal fade"
            aria-modal="true"
            role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{ title }</h4>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={onClose}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{ text }</p>
                    </div>
                    <div className="modal-footer justify-flex-end">
                        <button type="button" className="btn btn-default me-3" onClick={onNegative}>Não</button>
                        <button type="button" className="btn btn-success" onClick={onPositive}>Sim</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

Dialog.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    onPositive: PropTypes.func,
    onNegative: PropTypes.func,
    onClose: PropTypes.func
}

export default Dialog