import {
    ACTION_TYPE_USER_AUTHENTICATED,
    ACTION_TYPE_USER_LOGOUT,
    ACTION_TYPE_TOKEN_VALIDATED
} from './authActionTypes'

const userKey = 'cartinhand_user'

const initialState = {
    user: JSON.parse(localStorage.getItem(userKey)),
    valid: false
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE_USER_AUTHENTICATED: {
            localStorage.setItem(userKey, JSON.stringify(action.payload))
            return { ...state, user: action.payload, valid: true }
        }
        case ACTION_TYPE_TOKEN_VALIDATED: {
            if (action.payload) {
                return { ...state, valid: true }
            } else {
                localStorage.removeItem(userKey)
                return { ...state, valid: false, user: null }
            }
        }
        case ACTION_TYPE_USER_LOGOUT: {
            localStorage.removeItem(userKey)
            return { ...state, valid: false, user: null }
        }
        default:
            return state
    }
}

