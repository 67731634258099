import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import 'admin-lte/dist/css/adminlte.css'
import 'bootstrap'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'admin-lte/dist/js/adminlte'
import 'react-toastify/dist/ReactToastify.css'
import $ from 'jquery'

import { HashRouter as Router } from 'react-router-dom'
import { storeConfig } from './config/storeConfig';
import { ToastContainer } from 'react-toastify'

const root  = ReactDOM.createRoot(document.getElementById('root'))
const store = storeConfig()


root.render(
    <Router>
      <Provider store={store}>
        <App />
        <ToastContainer position="bottom-right" />
      </Provider>
    </Router>
)
