import { toast } from 'react-toastify'
import { apiClient } from '../config/api'
import {
    ACTION_TYPE_USER_AUTHENTICATED,
    ACTION_TYPE_USER_LOGOUT,
    ACTION_TYPE_TOKEN_VALIDATED
} from './authActionTypes'

export function login(email, password) {
    return dispatch => {
        apiClient.post('/oapi/auth/signin', { email, password })
            .then(resp => {
                dispatch({ type: ACTION_TYPE_USER_AUTHENTICATED, payload: resp.data })
            })
            .catch(err => {
                if (err.response.status === 0) {
                    toast.error('Falha na autenticação')
                } else {
                    const errors = err.response.data.errors
                    toast.error(errors[0])
                }
            })
    }
}

export function logout() {
    return { type: ACTION_TYPE_USER_LOGOUT }
}

export function validateToken(token) {
    if (!token)
        return
    
    return dispatch => {
        apiClient.post(`/oapi/auth/validateToken?authorization=${token}`)
            .then(resp => {
                dispatch({ type: ACTION_TYPE_TOKEN_VALIDATED, payload: resp.data.valid })
            }).catch(() => {
                dispatch({ type: ACTION_TYPE_TOKEN_VALIDATED, payload: false })
            })
    }
}