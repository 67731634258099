import React from 'react'

const EmailField = props => {
    const {
        className,
        input,
        placeholder,
        meta: { touched, error }
    } = props

    return (
            <div className={`${className || ''} input-group`}>
            <input
                { ...props  }
                { ...input }
                type="email"
                className="form-control"
                placeholder={placeholder} />
                <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                    </div>
                </div>
        </div>
    )
}

export default EmailField