import { ACTION_TYPE_PLACES_FETCHED, ACTION_TYPE_PLACES_FETCHING } from "./placesActionTypes"

const initialState =  {
    data: null,
    loading: false
}

export function placesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE_PLACES_FETCHED:
            return { ...state, data: action.payload }
        case ACTION_TYPE_PLACES_FETCHING:
            return { ...state, loading: action.payload }
        default:
            return state
    }
}