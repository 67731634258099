import React from 'react'
import PropTypes from 'prop-types'

const SmallBox = props => {
    const {
        className,
        value,
        variant,
        title,
        icon
    } = props
    
    return (
        <div className={`${className || ''} small-box bg-${variant}`}>
            <div className="inner">
                <h3>{value}</h3>
                <p>{title}</p>
            </div>
            <div className="icon">
                <i className={icon}></i>
            </div>
            {/* <a href="#" class="small-box-footer">
                More info <i class="fas fa-arrow-circle-right"></i>
            </a> */}
        </div>
    )
}

SmallBox.propTypes = {
    variant: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string.isRequired ]),
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
}

export default SmallBox